<template>
  <div class="wrapper">
    <div class="tips">
      <div class="t1">赛前必读</div>
      <div class="t2">了解相关规则，不容出错</div>
      <van-button class="btn1" type="default" @click="clickTips">去查看</van-button>
    </div>
    <div class="test" @click="clickTest">
      <div class="t3">赛前模拟</div>
      <img src="@/assets/image1@2x.png"/>
    </div>
    <div class="start" @click="clickStart">
      <div class="t3">正式比赛</div>
      <img src="@/assets/image2@2x.png"/>
    </div>
  </div>
</template>
<script>
import 'vant/lib/index.css';
import { Button } from "vant";
export default {
  name: "CJBBefore",
  components: {
    VanButton: Button,
  },
  data() {
    return {
      clickOnce: false
    };
  },
  created() {
    document.title = "线上比赛";
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (!token) {
      this.$router.push({
        name: "Login"
      });
    }
  },
  mounted() {
  },
  methods: {
    clickTips() {
      this.$router.push({
        name: "Tips"
      });
    },
    clickTest() {
      this.$router.push({
        name: "Confirm",
        query: {
          type: 0
        }
      });
    },
    clickStart() {
      if (this.clickOnce) {
        return;
      }
      this.clickOnce = true;
      let _this = this;
      this.clickOnce = false;
      this.$axios
        .get(this.global.baseUrl + `/api/ChangjiangbeiMatch/Cjb9thMatchInfo`)
        .then((res) => {
          if (res.code == 1000) {
            if (res.data == null) {
              _this.$router.push({
                name: "Confirm",
                query: {
                  type: 1
                }
              });
            } else {
              _this.$router.push({
                name: "Index",
                query: {
                  type: 2
                }
              });
            }
          }
        });
    }
  }
};
</script>
<style scoped>
.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #F0F3FF;
}

.tips {
  position: absolute;
  left: 7.5%;
  top: 0.51rem;
  width: 85%;
  height: 0.92rem;
  background: #FFFFFF;
  border-radius: 0.11rem 0.11rem 0.11rem 0.11rem;
}

.t1 {
  position: absolute;
  left: 0.51rem;
  top: 0.16rem;
  width: 2rem;
  height: 0.34rem;
  font-size: 0.29rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;
  line-height: 0.34rem;
}

.t2 {
  position: absolute;
  left: 0.51rem;
  top: 0.52rem;
  width: 3rem;
  height: 0.27rem;
  font-size: 0.22rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #929292;
  line-height: 0.25rem;
}

.btn1 {
  position: absolute;
  right: 0.29rem;
  top: 0.16rem;
  line-height: 0.58rem;
}

.van-button--default {
  width: 1.74rem;
  color: #fff !important;
  border-radius: 0.38rem 0.38rem 0.38rem 0.38rem !important;
  font-weight: 500 !important;
  height: 0.58rem !important;
  font-size: 0.29rem !important;
  border: 1px solid #5175FB !important;
  background-color: #5175FB !important;
  margin-left: 0.16rem;
}

.test {
  position: absolute;
  left: 7.5%;
  top: 2.05rem;
  width: 85%;
  height: 1.87rem;
  background: linear-gradient(180deg, #839DFF 0%, #5175FB 100%);
  box-shadow: 0.07rem 0.07rem 0.07rem 0rem rgba(173,192,255,0.25);
  border-radius: 0.11rem 0.11rem 0.11rem 0.11rem;
}

.start img, .test img {
  position: absolute;
  right: -0.12rem;
  top: -0.49rem;
  width: 2.97rem;
  height: 2.97rem;
}

.start {
  position: absolute;
  left: 7.5%;
  top: 4.66rem;
  width: 85%;
  height: 1.87rem;
  background: linear-gradient(180deg, #839DFF 0%, #5175FB 100%);
  box-shadow: 0.07rem 0.07rem 0.07rem 0rem rgba(173,192,255,0.25);
  border-radius: 0.11rem 0.11rem 0.11rem 0.11rem;
}

.t3 {
  position: absolute;
  left: 0.72rem;
  top: 0.67rem;
  width: 2rem;
  height: 0.53rem;
  font-size: 0.43rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.51rem;
}

</style>
