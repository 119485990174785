import Vue from "vue";
import Router from "vue-router";
import Login from "@/views/Login";
import Before from "@/views/Before";
import Confirm from "@/views/Confirm";
import Notice from "@/views/Notice";
import Tips from "@/views/Tips";
import Index from "@/views/Index";

Vue.use(Router);

export default new Router({
    mode: "history",
    base: "",
    routes: [
        {
            path: "/",
            name: "Home",
            component: Login
        },
        {
            path: "/login",
            name: "Login",
            component: Login
        },
        {
            path: "/before",
            name: "Before",
            component: Before
        },
        {
            path: "/confirm",
            name: "Confirm",
            component: Confirm
        },
        {
            path: "/notice",
            name: "Notice",
            component: Notice
        },
        {
            path: "/tips",
            name: "Tips",
            component: Tips
        },
        {
            path: "/index",
            name: "Index",
            component: Index
        }
    ]
});
