<template>
  <div class="wrapper">
    <div class="v1" v-show="showStart">
      <div class="title">拍摄要求</div>
      <div class="t1">以下内容非常重要，请认真仔细阅读</div>
      <div class="line1"></div>

      <div class="v1_tips">
        <div class="v1_t1">示例视频</div>
        <van-button class="btn1" type="default" @click="clickVideo">去查观看</van-button>
      </div>

      <div class="t2">
        1、使用手机后置摄像头横屏拍摄（请勿使用平板）；<br><br>
        2、支持安卓5.0以上版本，苹果IOS9以上版本；<br><br>
        3、在wifi环境，开启飞行模式；使用手机流量上网的环境下，请打开手机的勿扰模式；<br><br>
        4、进入比赛页面前，在手机设置中确认“网络”、“麦克风”和“照相机”权限已打开；<br><br>
        5、在拍摄过程中，请勿进行任何的手机操作，如：切换系统到后台运行、点击手机的返回键【苹果（home）】和锁屏键等；<br><br>
        6、在拍摄过程中，如遇到手机的消息推送时，如微信，短信等弹出时，请勿理会，不要点击，直接忽略即可，因点击导致视频录制的中断，将失去录制机会；<br><br>
        7、请根据钢琴引导框拍摄，录制画面只允许出现参赛者一人，全程不得离开画面，不可背对镜头；<br><br>
        8、录制完成后，请勿直接关闭退出网页，请等待视频上传结束后，根据系统提示再退出。
      </div>
      <van-button class="next" type="primary" @click="clickStart">进入比赛录制</van-button>
      <!-- <div class="local_video" v-show="showVideo">
          <video id="local_video" style="position:absolute;left:0;top:0;width:375px;height:667px;" autoplay playsinline></video>
      </div> -->
    </div>

    <div class="v2" v-show="showTips">
      <div class="v2bg"></div>
      <div class="v2_box">
        <div class="v2_box_title">注意</div>
        <div class="line2"></div>
        <div class="v2_box_t1">录制流程：调整拍摄角度→拍摄参赛者正脸视频→参赛者就位演奏参赛曲目</div>
        <div class="v2_box_t2">
          <a style="color: red;">1、点击开始后请尽快开始拍摄，拍摄时限为10分钟，请将屏幕锁定时间调为“永不”，请勿使用重拍，否则按作弊处理。</a><br><br>
          2、使用手机后置摄像头横屏拍摄（请勿使用平板）；<br><br>
          3、钢琴键盘位于屏幕正中央，确保可以看到参赛者双手、侧脸；<br><br>
          4、演奏前，参赛者来到镜头前，正脸面对镜头5秒，要求能看清五官。<br><br>
          5、参赛者人像录制完成后，就位演奏参赛曲目。
        </div>
        <van-button class="v2_box_next" type="primary" @click="clickCloseTips">我知道了{{ countdown }}</van-button>
      </div>
    </div>

    <div class="v3" v-show="showUpload">
      <div class="v3_title">视频预览</div>
      <div class="line1"></div>
      <div class="v3_t1">视频信息</div>
      <div class="v3_video">
        <video class="videobox" id="videoId"
          autoplay playsinline
        ></video>
        <!-- muted autoplay preload loop
        x5-video-player-fullscreen="true"
        x5-playsinline
        playsinline
        webkit-playsinline
        controls -->
      </div>
      <van-button class="v3_btn" type="primary" @click="clickUpload" v-if="url != '' || type == 2 ? false : true">去上传视频</van-button>
      <div class="line3"></div>
      <div class="v3_t2">参赛者信息</div>
      <div class="box">
        <div class="box_username">{{ username }}</div>
        <div class="box_line" style="top: 1.3rem;border-bottom: 0.02rem solid #D9DEF2;height: 0.54rem;">
          <div>姓名</div>
          <div>{{ username }}</div>
        </div>
        <div class="box_line" style="top: 2.12rem;border-bottom: 0.02rem solid #D9DEF2;height: 0.74rem;">
          <div>参赛组别</div>
          <div style="font-size: 0.2rem;text-align: right;">
            第九届“长江钢琴杯”青少年音乐比赛<br>
            武汉音乐学院赛区专业组
          </div>
        </div>
        <div class="box_line" style="top: 3.06rem;border-bottom: 0.02rem solid #D9DEF2;height: 0.54rem;">
          <div>比赛时间</div>
          <div>{{ nowTime }}</div>
        </div>
        <div class="box_line" style="top: 3.8rem;">
          <div>剩余比赛次数</div>
          <div>{{ times[type] }}</div>
        </div>
      </div>
      <!-- <input ref="camera" id="file" name="file" type="file"
        capture="camera" accept="video/*" style="display: none;"
        @change="change"
      > -->
      <input
          id="cameraPublisher"
          value="cameraPublisher"
          type="checkbox"
          name="streamType"
          checked
          style="display: none;"
      />
    </div>

    <div class="v5" v-show="showShili">
      <div class="videobg"></div>
      <span class="detail_icon_close" @click="toBack"></span>
      <video class="shilivideo"
        muted autoplay preload loop
        x5-video-player-fullscreen="true"
        x5-playsinline
        playsinline
        webkit-playsinline
        controls
      >
        <source src="https://yzm-images.oss-cn-beijing.aliyuncs.com/video/cjb9th/shifan.mp4" type="video/mp4" >
      </video>
    </div>

    <div class="v4" v-show="showLoading">
      <div class="v2bg"></div>
      <van-loading style="margin-top: 6rem;" size="0.5rem" vertical>上传中...</van-loading>
    </div>
  </div>
</template>
<script>
// zey4srvd
// 42d16d7a22be208237f98fe01eb82c21
import 'vant/lib/index.css'
import 'aliyun-webrtc-sdk'
import CryptoJS from "crypto-js";
import { Button, Loading } from "vant";
import sha256 from 'js-sha256'
export default {
  components: {
    VanButton: Button,
    VanLoading: Loading
  },
  data() {
    return {
      type: 0,
      count: 5,
      url: '',
      countTimer: null,
      showStart: true,
      showTips: true,
      showUpload: false,
      showLoading: false,
      showShili: false,
      showVideo: false,
      username: '姓名',
      nowTime: '2022-11-08',
      times: [10000, 1, 0],
      startTimer: 0,
      endTimer: 0,
      duration: 0,
      aliWebrtc: null
    };
  },
  computed: {
    countdown() {
      return this.count <= 0 ? "" : "（" + this.count + "秒）";
    }
  },
  created() {
    // const token = localStorage.getItem("ACCESS_TOKEN");
    // if (!token) {
    //   this.$router.push({
    //     name: "Login"
    //   });
    // }
    this.type = this.$route.query.type == null ? 0 : this.$route.query.type;
    let _this = this;
    if (this.type == 0 || this.type == 1) {
      this.showTips = true;
      this.countTimer = setInterval(() => {
        this.count--;
        if (this.count <= 0) {
          clearInterval(this.countTimer);
        }
      }, 1000);
    }

    this.aliWebrtc = new AliRtcEngine({
      autoPublish: true,
      autoSubscribe: false
    });
    var option = {
      isReceiveOnly: false,
      isDebug: true,
      customPlay: false
    }
    this.aliWebrtc.isSupport(option).then(re => {
      // alert("支持");
      _this.aliWebrtc.configLocalAudioPublish = true;
      _this.aliWebrtc.configLocalCameraPublish = true;
      _this.aliWebrtc.configLocalScreenPublish = false;
    }).catch(error => {
      // alert("不支持");
      alert(error.message);
    })
  },
  mounted() {
  },
  methods: {
    randomString(len) {
      len = len || 32;
      var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      var maxLen = $chars.length;
      var str = '';
      for (var i = 0; i < len; i++) {
        str += $chars.charAt(Math.floor(Math.random() * maxLen));
      }
      return str;
    },
    clickStart() {
      // if (Date.now() > 1668070800000) {
      //   alert("比赛时间已截止。");
      //   return;
      // }
      this.showStart = false;
      this.showUpload = true;
      let _this = this;
      //1.预览
      this.aliWebrtc.videoProfile = {
        frameRate: 20,
        width: 667,
        height: 375
      };
      var localVideo = document.getElementById('videoId');
      this.aliWebrtc.startPreview(localVideo).then((obj) => {
        //alert("预览成功");
      }).catch((error) => {
        alert(error.message);
      });

      //2. 获取频道鉴权令牌参数
      let info = this.generateAliRtcAuthInfo("test002");

      // LTAI5tHTQ4NCFishQMDAWDMe
      // hWPImZpTnkHpd9dn2B87BEOe2QoOwH
      //3. 加入房间 默认推音频视频流
      this.aliWebrtc.joinChannel(info, "displayName").then(() => {
          // 入会成功
          _this.showVideo = true;
          _this.aliWebrtc.configLocalAudioPublish = true;
          _this.aliWebrtc.configLocalCameraPublish = true;
          _this.aliWebrtc.configLocalScreenPublish = true;
          _this.aliWebrtc.publish().then(() => {
            let taskId = "TaskId" + _this.randomString(5);
            let nonce = _this.randomString(32);
            let tp = _this.timestamp();
            let str = "GET&" + _this.encode("/") + "&" + _this.encode("AccessKeyId=LTAI5tHTQ4NCFishQMDAWDMe&Action=StartRecordTask&" +
              "AppId=zey4srvd&ChannelId=test002&Format=json&SignatureMethod=HMAC-SHA1&" +
              "SignatureNonce=" + nonce + "&SignatureVersion=1.0&TaskId=" + taskId +
              "&TemplateId=fsUqTdjk&Timestamp=" + tp + "&Version=2018-01-11");
            console.log(str);
            let signature = CryptoJS.HmacSHA1(str, "hWPImZpTnkHpd9dn2B87BEOe2QoOwH&");
            _this.$axios
              .get("/record/?Action=StartRecordTask", {
                params: {
                  AccessKeyId: "LTAI5tHTQ4NCFishQMDAWDMe",
                  AppId: "zey4srvd",
                  ChannelId: "test002",
                  Format: "json",
                  Signature: signature.toString(CryptoJS.enc.Base64),
                  SignatureMethod: "HMAC-SHA1",
                  SignatureNonce: nonce,
                  SignatureVersion: "1.0",
                  TaskId: taskId,
                  TemplateId: "fsUqTdjk",
                  Timestamp: tp,
                  Version: "2018-01-11"
                }
              })
              .then((res) => {
                if (res.code == 1000) {
                  if (res.data == null) {

                  } else {
                    _this.url = res.data.url;
                    let videoId = document.getElementById('videoId')
                    videoId.src = _this.url.split("?uploadId")[0]
                    _this.nowTime = res.data.gmtCreate.slice(0, 10);
                  }
                }
              });
          } ,(error) => {
              console.log(error.message);
          });
      }, (error) => {
          // 入会失败，打印错误内容，可以看到失败原因
          console.log(error.message);
      });
      this.startTimer = Date.now();
    },
    clickCloseTips() {
      if (this.count <= 0) {
        this.showTips = false
      }
    },
    change() {
      let files = document.getElementById("file").files;
      let url = this.getFileURL(files[0])
      if (url) {
        let videoId = document.getElementById('videoId')
        let _this = this;
        videoId.onloadedmetadata = function() {
          _this.duration = videoId.duration
        };
        // if (this.isAndroid()) {
        //   _this.initFfmpeg(files[0])
        // } else {
          videoId.src = url
        // }
      }
      this.showStart = false;
      this.showUpload = true;
      // 结束倒计时
      this.endTimer = Date.now();
    },
    clickUpload() {
      if (Date.now() > 1668070800000) {
        alert("比赛时间已截止。");
        return;
      }
      this.showLoading = true;
      let _this = this;
      if (this.duration > 900) {
        alert("视频超过10分钟，无法上传！");
        return;
      }

    },
    getFileURL (file) {
      let getUrl = null
      if (window.createObjectURL != undefined) {
        getUrl = window.createObjectURL(file)
      } else if (window.URL != undefined) {
        getUrl = window.URL.createObjectURL(file)
      } else if (window.webkitURL != undefined) {
        getUrl = window.webkitURL.createObjectURL(file)
      }
      return getUrl
    },
    clickVideo() {
      this.showShili = true;
    },
    toBack() {
      this.showShili = false;
    },
    generateAliRtcAuthInfo(channelId) {
        var appId = "zey4srvd";
        var key = "42d16d7a22be208237f98fe01eb82c21";
        var userId = "6b17b67c-2d04-439b-9811-150728f22bdd";
        // var userId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        //     var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        //     return v.toString(16);
        // });
        var timestamp = parseInt(new Date().getTime() / 1000 + 48 * 60 * 60);
        var nonce = 'AK-' + timestamp;
        var token = sha256(appId + key + channelId + userId + nonce + timestamp);
        return {
            appid: appId,
            userid: userId,
            timestamp: timestamp,
            nonce: nonce,
            token: token,
            gslb: ["https://rgslb.rtc.aliyuncs.com"],
            channel: channelId
        };
    },
    encode(str) {
      var result = encodeURIComponent(str);
      return result.replace(/!/g, '%21')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/\*/g, '%2A')
        .replace(/%3A/g, '%253A');
    },
    timestamp() {
      var date = new Date();
      var YYYY = date.getUTCFullYear();
      var MM = date.getUTCMonth() + 1 < 10 ? "0" + (date.getUTCMonth() + 1).toString() : (date.getUTCMonth() + 1).toString();
      var DD = date.getUTCDate() < 10 ? "0" + date.getUTCDate().toString() : date.getUTCDate().toString();
      var HH = date.getUTCHours() < 10 ? "0" + date.getUTCHours().toString() : date.getUTCHours().toString();
      var mm = date.getUTCMinutes() < 10 ? "0" + date.getUTCMinutes().toString() : date.getUTCMinutes().toString();
      var ss = date.getUTCSeconds() < 10 ? "0" + date.getUTCSeconds().toString() : date.getUTCSeconds().toString();
      // 删除掉毫秒部分
      return `${YYYY}-${MM}-${DD}T${HH}:${mm}:${ss}Z`;
    }
  }
};
</script>
<style scoped>
.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
}

.v1, .v3 {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 13.34rem;
  overflow-y: scroll;
}

.v1_tips {
  position: absolute;
  left: 7.5%;
  top: 1.51rem;
  width: 85%;
  height: 0.92rem;
  background: #FFFFFF;
  border-radius: 0.4rem;
  border: 0.01rem solid #D9DEF2;
}

.v1_t1 {
  position: absolute;
  left: 0.51rem;
  top: 0.3rem;
  width: 2rem;
  height: 0.34rem;
  font-size: 0.29rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;
  line-height: 0.34rem;
}

.v2, .v4, .v5 {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.v2bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.7;
}

.videobg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.9;
}

.v2_box {
  position: absolute;
  left: 0.56rem;
  top: 2rem;
  width: 6.36rem;
  height: 8.44rem;
  background: #FFFFFF;
  box-shadow: 0.08rem 0.08rem 0.08rem 0rem rgba(81,117,251,0.3);
  border-radius: 0.52rem 0.52rem 0.52rem 0.52rem;
}

.v2_box_title {
  position: absolute;
  left: 0;
  top: 0.3rem;
  width: 100%;
  height: 0.48rem;
  font-size: 0.4rem;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: #5175FB;
  line-height: 0.47rem;
  text-align: center;
}

.line2 {
  position: absolute;
  left: 0;
  top: 0.96rem;
  width: 6.32rem;
  border: 0.02rem solid #D9DEF2;
}

.v2_box_t1 {
  position: absolute;
  left: 0.72rem;
  top: 1.42rem;
  width: 5.18rem;
  height: 4.76rem;
  font-size: 0.28rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #5175FB;
  line-height: 0.33rem;
}

.v2_box_t2 {
  position: absolute;
  left: 0.72rem;
  top: 2.42rem;
  width: 5.18rem;
  height: 4.76rem;
  font-size: 0.28rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #000;
  line-height: 0.33rem;
}

.v2_box_next {
  position: absolute;
  left: 1.05rem;
  top: 8.12rem;
  width: 4.34rem;
  height: 0.64rem;
}

.title {
  position: absolute;
  left: 0.72rem;
  top: 0.33rem;
  width: 2rem;
  height: 0.34rem;
  font-size: 0.29rem;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: #5175FB;
  line-height: 0.34rem;
}

.t1 {
  position: absolute;
  left: 0.72rem;
  top: 0.71rem;
  width: 4rem;
  height: 0.22rem;
  font-size: 0.18rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #F13A3A;
  line-height: 0.21rem;
}

.line1 {
  position: absolute;
  left: 0;
  top: 1.16rem;
  width: 99.2%;
  opacity: 1;
  border: 0.02rem solid #D9DEF2;
}

.t2 {
  position: absolute;
  left: 0.72rem;
  top: 2.81rem;
  width: 78.8%;
  height: auto;
  font-size: 0.25rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;
  line-height: 0.3rem;
}

.next {
  position: absolute;
  left: 1.3rem;
  top: 10.84rem;
  width: 4.92rem;
  height: 0.64rem;
  line-height: 0.64rem;
}

.van-button--primary {
  color: #fff !important;
  font-weight: 400 !important;
  padding: 0 0.9rem !important;
  border: none !important;
  border-radius: 0.38rem !important;
  font-size: 0.29rem !important;
  background: #5175FB !important;
}

.v3 {
  position: absolute;
  width: 100%;
  height: auto;
  background: #F0F3FF;
}

.v3_title {
  position: absolute;
  left: 0;
  top: 0.46rem;
  width: 100%;
  height: 0.38rem;
  font-size: 0.32rem;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: #5175FB;
  line-height: 0.38rem;
  text-align: center;
}

.v3_t1 {
  position: absolute;
  left: 0.64rem;
  top: 1.58rem;
  width: 2rem;
  height: 0.38rem;
  font-size: 0.32rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;
  line-height: 0.38rem;
}

.v3_video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #D9D9D9;
}

.v3_btn {
  position: absolute;
  left: 2.02rem;
  top: 5.64rem;
  width: 3.44rem;
  height: 0.64rem;
}

.line3 {
  position: absolute;
  left: 0;
  top: 6.8rem;
  width: 99.2%;
  border: 0.02rem solid #D9DEF2;
}

.v3_t2 {
  position: absolute;
  left: 0.68rem;
  top: 7.3rem;
  width: 2.6rem;
  height: 0.38rem;
  font-size: 0.32rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;
  line-height: 0.38rem;
}

.box {
  position: absolute;
  left: 0.72rem;
  top: 8.14rem;
  width: 6.06rem;
  height: 4.74rem;
  background: #FFFFFF;
  box-shadow: 0rem 0.08rem 0.06rem 0rem rgba(89,112,197,0.1);
  border-radius: 0.38rem 0.38rem 0.38rem 0.38rem;
}

.box_username {
  position: absolute;
  left: 0;
  top: 0.36rem;
  width: 100%;
  height: 0.48rem;
  font-size: 0.4rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #5175FB;
  line-height: 0.47rem;
  text-align: center;
}

.box_line {
  position: absolute;
  left: 0.3rem;
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 0.34rem;
  font-size: 0.28rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;
  line-height: 0.33rem;
}

.videobox {
  width: 100%;
  height: 100%;
}

.shilivideo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  opacity: 1;
  margin-top: 4rem;
  background: #000;
}

.btn1 {
  position: absolute;
  right: 0.29rem;
  top: 0.16rem;
  line-height: 0.58rem;
}

.van-button--default {
  width: 2rem;
  color: #fff !important;
  border-radius: 0.38rem 0.38rem 0.38rem 0.38rem !important;
  font-weight: 500 !important;
  height: 0.58rem !important;
  font-size: 0.29rem !important;
  border: 1px solid #5175FB !important;
  background-color: #5175FB !important;
  margin-left: 0.16rem;
}

.detail_icon_close {
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
  width: 0.5rem;
  height: 0.5rem;
  background: url("../assets/close_w.svg") no-repeat left center/0.5rem 0.5rem;
}

.local_video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
