<template>
  <div class="wrapper">
    <div class="title">信息确认</div>
    <div class="line1"></div>
    <div class="username">{{ username }}</div>
    <div class="tips">{{ tips[type] }}</div>
    <div class="box">
      <div class="box_line" style="top: 0.6rem;border-bottom: 0.02rem solid #D9DEF2;height: 0.54rem;">
        <div>姓名</div>
        <div>{{ username }}</div>
      </div>
      <div class="box_line" style="top: 1.42rem;border-bottom: 0.02rem solid #D9DEF2;height: 0.74rem;">
        <div>参赛组别</div>
        <div style="font-size: 0.2rem;text-align: right;">
          第九届“长江钢琴杯”青少年音乐比赛<br>
          武汉音乐学院赛区专业组
        </div>
      </div>
      <div class="box_line" style="top: 2.36rem;border-bottom: 0.02rem solid #D9DEF2;height: 0.54rem;">
        <div>比赛时间</div>
        <div>{{ nowTime }}</div>
      </div>
      <div class="box_line" style="top: 3.1rem;">
        <div>剩余比赛次数</div>
        <div>{{ times[type] }}</div>
      </div>
    </div>
    <van-button class="next" type="primary" @click="clickNext">确认无误，开始比赛</van-button>
  </div>
</template>
<script>
import 'vant/lib/index.css'
import { Button } from "vant";
export default {
  name: "CJBConfirm",
  components: {
    VanButton: Button
  },
  data() {
    return {
      type: 0,
      username: '姓名',
      nowTime: '2022-11-5',
      tips: [
        '模拟比赛不会消耗比赛次数',
        '仅一次比赛机会，请准备就绪后比赛'
      ],
      times: [10000, 1]
    };
  },
  created() {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (!token) {
      this.$router.push({
        name: "Login"
      });
    }
    this.type = this.$route.query.type == null ? 0 : this.$route.query.type;
    let _this = this;
    this.$axios
      .get(this.global.baseUrl + `/api/ChangjiangbeiMatch/Cjb9thCheckInfo`)
      .then((res) => {
        if (res.code == 1000) {
          _this.username = res.data.userName;
          _this.nowTime = res.data.entryTime;
        }
      });
  },
  methods: {
    clickNext() {
      this.$router.push({
        name: "Notice",
        query: {
          type: this.type
        }
      });
    }
  }
};
</script>

<style scoped>
.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
}

.title {
  position: absolute;
  left: 0;
  top: 0.42rem;
  width: 100%;
  height: 0.34rem;
  font-size: 0.29rem;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: #5175FB;
  line-height: 0.34rem;
  text-align: center;
}

.line1 {
  position: absolute;
  left: 0;
  top: 1.16rem;
  width: 99.2%;
  opacity: 1;
  border: 0.02rem solid #D9DEF2;
}

.username {
  position: absolute;
  left: 1.12rem;
  top: 2.23rem;
  width: 3rem;
  height: 0.43rem;
  font-size: 0.36rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #5175FB;
  line-height: 0.42rem;
}

.tips {
  position: absolute;
  left: 1.12rem;
  top: 2.86rem;
  width: 5rem;
  height: 0.27rem;
  font-size: 0.22rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #F13A3A;
  line-height: 0.25rem;
}

.box {
  position: absolute;
  left: 0.65rem;
  top: 3.64rem;
  width: 6.15rem;
  height: 3.84rem;
  background: #F0F3FF;
  box-shadow: 0rem 0.07rem 0.05rem 0rem rgba(89,112,197,0.1);
  border-radius: 0.34rem 0.34rem 0.34rem 0.34rem;
}

.box_line {
  position: absolute;
  left: 0.3rem;
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 0.34rem;
  font-size: 0.28rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;
  line-height: 0.33rem;
}

.next {
  position: absolute;
  left: 1.16rem;
  top: 9.84rem;
  width: 5.2rem;
  height: 0.58rem;
}

.van-button--primary {
  color: #fff !important;
  font-weight: 400 !important;
  padding: 0 0.9rem !important;
  border: none !important;
  border-radius: 0.38rem !important;
  font-size: 0.29rem !important;
  background: #5175FB !important;
}
</style>
