import Vue from 'vue'
import App from './App.vue'
import router from './router';

Vue.config.productionTip = false

import axios from 'axios';
import "./utils/axios";
Vue.prototype.$axios = axios;

import global from './utils/global';
Vue.prototype.global = global;

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
