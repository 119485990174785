//本地调试
// const baseUrl = 'http://api.a.com:51557/';
// const appId = 'wx471c393f1526e4f9'

//正式服
// const baseUrl = 'http://api.ishzm.com/';
// const appId = 'wx797e1f1e48f6fa24';

//测试服
// const baseUrl = 'http://api.test.ishzm.com/';
// const appId = 'wx05388fedf7015b38';

// wx797e1f1e48f6fa24 音掌门公众号
// wx05388fedf7015b38 奏鸣测试公众号
// wx471c393f1526e4f9 james测试号

let baseUrl = 'https://api.ishzm.com';
let env = 'prod';
if (location.hostname === "cjb9th.teachmusic.cn") {
    baseUrl = 'proxyapi';
    env = 'prod';
} else if (location.hostname === "cjb9th.test.teachmusic.cn") {
    baseUrl = 'proxyapi';
    env = 'test';
} else if (location.hostname === "web.a.com" || location.hostname === "localhost" || location.hostname === "192.168.5.192") {
    baseUrl = 'proxyapi';
    env = 'dev';
}

export default {
    baseUrl,
    env
}
