<template>
  <div class="wrapper">
    <div class="title">第九届“长江钢琴杯”<br>青少年音乐比赛</div>
    <div class="subtitle">武汉音乐学院赛区<br>专业组钢琴比赛</div>
    <div class="login_box">
      <div class="login_name">
        <div>姓名</div>
        <input v-model:value="userName"/>
      </div>
      <div class="login_phone">
        <div>手机号</div>
        <input v-model:value="phone"/>
      </div>
      <div class="verification">
        <div>验证码</div>
        <input v-model:value="valCode"/>
        <van-button v-show="show" type="default" @click="clickVerification">获取验证码</van-button>
        <div class="countdown" v-show="!show">{{ count }}秒</div>
      </div>
    </div>
    <van-button class="login_btn" type="primary" @click="clickLogin">登录</van-button>
  </div>
</template>

<script>
import 'vant/lib/index.css'
import { Button, Toast } from "vant";
import { phoneReg } from "../utils/regexp";
export default {
  name: "CJBLogin",
  components: {
    Toast,
    VanButton: Button,
  },
  data() {
    return {
      count: 60,
      show: true,
      authTimer: null,
      phone: '',
      userName: '',
      valCode: '',
      clickOnce: false
    };
  },
  created() {
    document.title = "第九届“长江钢琴杯”";
  },
  mounted() {
  },
  methods: {
    clickVerification() {
      if (this.clickOnce) {
        return;
      }
      this.clickOnce = true;
      if (!phoneReg.test(this.phone)) {
        Toast({
          position: "top",
          message: "请输入有效的手机号！",
        });
        this.clickOnce = false;
        return;
      }
      this.show = false;
      this.count = 60;
      this.authTimer = setInterval(() => {
        this.count--;
        if (this.count <= 0) {
          this.show = true;
          clearInterval(this.authTimer);
        }
      }, 1000);
      this.clickOnce = false;
      this.$axios
        .get(this.global.baseUrl + `/api/ChangjiangbeiMatch/CjbValidate`, {
          params: {
            phone: this.phone
          },
        })
        .then((res) => {
          if (res.code != 1000) {
            Toast.fail({
              message: res.message
            });
          }
        });
    },
    clickLogin() {
      if (this.clickOnce) {
        return;
      }
      this.clickOnce = true;
      if (this.phone == '' || this.userName == '' || this.valCode == '') {
        Toast.fail({
          message: "参数错误！"
        });
        this.clickOnce = false;
        return;
      }
      this.clickOnce = false;
      let _this = this;
      this.$axios
        .get(this.global.baseUrl + `/api/ChangjiangbeiMatch/Cjb9thLogin`, {
          params: {
            phone: this.phone,
            userName: this.userName,
            valCode: this.valCode
          },
        })
        .then((res) => {
          if (res.code == 1000) {
            if (res.data.token) {
              localStorage.setItem("ACCESS_TOKEN", res.data.token);
            }
            _this.$router.push({
              name: "Before"
            });
          } else {
            Toast.fail({
              message: res.message,
            });
          }
        });
    }
  }
};
</script>

<style scoped>
.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../assets/login_bg@2x.png") no-repeat;
  overflow: hidden;
  background-size: cover;
}

.title {
  position: absolute;
  left: 0;
  top: 18.3%;
  width: 100%;
  height: 1.59rem;
  font-size: 0.65rem;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: #3A61F3;
  line-height: 0.76rem;
  text-align: center;
}

.subtitle {
  position: absolute;
  left: 0;
  top: calc(18.3% + 1.62rem);
  width: 100%;
  height: 1.05rem;
  font-size: 0.43rem;
  font-family: Inter-Light, Inter;
  font-weight: 300;
  color: #3A61F3;
  line-height: 0.51rem;
  text-align: center;
}

.login_box {
  position: absolute;
  width: 80%;
  left: 13%;
  top: 42%;
  height: 2.2rem;
  font-size: 0.32rem;
  font-family: Inter-Regular, Inter;
  font-weight: 600;
  color: #303030;
  line-height: 0.58rem;
}

.login_name, .login_phone, .verification {
  display: flex;
  margin-top: 0.38rem;
  height: 0.62rem;
}

.login_name input {
  width: 4.06rem;
  height: 0.58rem;
  background: #FFFFFF;
  border-radius: 0.11rem 0.11rem 0.11rem 0.11rem;
  opacity: 1;
  margin-left: 0.58rem;
  padding-left: 10px;
  border: none;
}

.login_phone input {
  width: 4.06rem;
  height: 0.58rem;
  background: #FFFFFF;
  border-radius: 0.11rem 0.11rem 0.11rem 0.11rem;
  opacity: 1;
  border: none;
  margin-left: 0.28rem;
  padding-left: 10px;
  outline-color: #4F78FF;
}

.verification input {
  width: 2.16rem;
  height: 0.58rem;
  background: #FFFFFF;
  border-radius: 0.11rem 0.11rem 0.11rem 0.11rem;
  opacity: 1;
  border: none;
  margin-left: 0.28rem;
  padding-left: 10px;
}

input:focus {
  border: 0.04rem solid #4F78FF;
}

.login_btn {
  position: absolute;
  left: 12.5%;
  bottom: 0.94rem;
  width: 75%;
  height: 0.58rem;
}

.van-button--default {
  width: 1.74rem;
  color: #fff !important;
  border-radius: 0.11rem 0.11rem 0.11rem 0.11rem !important;
  font-weight: 500 !important;
  height: 0.58rem !important;
  font-size: 0.29rem !important;
  border: 1px solid #FF8B11 !important;
  background-color: #FF8B11 !important;
  margin-left: 0.16rem;
}

.countdown {
  width: 1.74rem;
  margin-left: 0.16rem;
  color: #000;
  font-size: 0.29rem;
  font-weight: 500;
  text-align: center;
}

.van-button--default .van-button__content {
  width: 1.64rem !important;
  margin-left: -0.22rem;
  padding: 0;
}

.van-toast {
  font-size: 1.5rem !important;
}

.van-button--primary {
  color: #fff !important;
  border-radius: 0.11rem 0.11rem 0.11rem 0.11rem !important;
  font-weight: 500 !important;
  height: 0.58rem !important;
  font-size: 0.3rem !important;
  border: 1px solid #5175FB !important;
  background-color: #5175FB !important;
}
</style>
