<template>
  <!-- 赛前必读 -->
  <div class="wrapper">
    <div class="title">赛前必读</div>
    <div class="t1">以下内容非常重要，请认真仔细阅读</div>
    <div class="line1"></div>
    <div class="t2">友情提醒：苹果手机请开启竖排方向锁定。安卓手机请关闭自动旋转屏幕。</div>
    <div class="t3">
      1、正式比赛前必须在赛前模拟界面模拟至少一次，以熟悉比赛录制过程；<br><br>
      2、赛前模拟是为了让参赛者熟悉录制的操作流程，以了解正式拍摄环境的操作，因此无法保存、上传视频；<br><br>
      3、参赛者所有比赛曲目需在同一视频内演奏完成，无需分开演奏；<br><br>
      4、每位参赛者只有一次录制机会，录制完成后，上传视频。评委将对该视频进行审核评定；<br><br>
      5、所有比赛曲目根据简章要求背谱演奏，视频中不得出现乐谱等相关物件；<br><br>
      6、所有参赛视频均需是参赛者本人当场演奏，中途不得切换镜头、不得使用扩音设备。如发现舞弊行为，将取消本次比赛成绩或资格；<br><br>
      7、点击“开始比赛”按钮后，请勿中途中断录制，以任何方式退出考试链接，都会消耗一次录制机会，请谨慎操作；
    </div>
    <van-button class="back" type="primary" @click="clickBack">我知道了</van-button>
  </div>
</template>

<script>
import 'vant/lib/index.css'
import { Button } from "vant";
export default {
  name: "CJBTips",
  components: {
    VanButton: Button,
  },
  data() {
    return {
    };
  },
  created() {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (!token) {
      this.$router.push({
        name: "Login"
      });
    }
  },
  methods: {
    clickBack() {
      this.$router.push({
        name: "Before"
      });
    }
  }
};
</script>

<style scoped>
.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
}

.title {
  position: absolute;
  left: 0.72rem;
  top: 0.33rem;
  width: 2rem;
  height: 0.34rem;
  font-size: 0.29rem;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: #5175FB;
  line-height: 0.34rem;
}

.t1 {
  position: absolute;
  left: 0.72rem;
  top: 0.71rem;
  width: 4rem;
  height: 0.22rem;
  font-size: 0.18rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #F13A3A;
  line-height: 0.21rem;
}

.line1 {
  position: absolute;
  left: 0;
  top: 1.16rem;
  width: 99.2%;
  opacity: 1;
  border: 0.02rem solid #D9DEF2;
}

.t2 {
  position: absolute;
  left: 0.72rem;
  top: 1.49rem;
  width: 78.8%;
  height: 0.62rem;
  font-size: 0.25rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #F13A3A;
  line-height: 0.3rem;
}

.t3 {
  position: absolute;
  left: 0.72rem;
  top: 2.54rem;
  width: 78.8%;
  height: auto;
  font-size: 0.25rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;
  line-height: 0.3rem;
}

.back {
  position: absolute;
  left: 1.16rem;
  bottom: 0.74rem;
  width: 5.2rem;
  height: 0.58rem;
}

.van-button--primary {
  color: #fff !important;
  font-weight: 400 !important;
  padding: 0 0.9rem !important;
  border: none !important;
  border-radius: 0.38rem !important;
  font-size: 0.29rem !important;
  background: #5175FB !important;
}
</style>
