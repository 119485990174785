<template>
  <!-- 赛前必读 -->
  <div class="wrapper">
    <div class="title">赛前须知</div>
    <div class="t1">以下内容非常重要，请认真仔细阅读</div>
    <div class="line1"></div>
    <div class="t3">
      1、正式比赛前必须在赛前模拟界面模拟至少一次，以熟悉比赛录制过程；<br><br>
      2、赛前模拟次数不限，正式比赛只有1次录制机会；<br><br>
      3、赛前模拟是为了让参赛者熟悉录制的操作流程，以了解正式拍摄环境的操作，因此无法保存、上传视频；<br><br>
    </div>
    <div class="t2">*正式比赛赛前须知请参赛者至赛前必读查看阅读</div>
    <van-button class="next" type="primary" @click="clickNext">我知道了</van-button>
    <span class="detail_icon_close" @click="toBack"></span>
  </div>
</template>

<script>
import 'vant/lib/index.css'
import { Button } from "vant";
export default {
  name: "CJBNotice",
  components: {
    VanButton: Button,
  },
  data() {
    return {
      type: 0
    };
  },
  created() {
    this.type = this.$route.query.type == null ? 0 : this.$route.query.type;
  },
  methods: {
    clickNext() {
      this.$router.push({
        name: "Index",
        query: {
          type: this.type,
        }
      });
    },
    toBack() {
      this.$router.push({
        name: "Before"
      });
    }
  }
};
</script>

<style scoped>
.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
}

.title {
  position: absolute;
  left: 0.72rem;
  top: 0.33rem;
  width: 2rem;
  height: 0.34rem;
  font-size: 0.29rem;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: #5175FB;
  line-height: 0.34rem;
}

.t1 {
  position: absolute;
  left: 0.72rem;
  top: 0.71rem;
  width: 4rem;
  height: 0.22rem;
  font-size: 0.18rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #F13A3A;
  line-height: 0.21rem;
}

.line1 {
  position: absolute;
  left: 0;
  top: 1.16rem;
  width: 99.2%;
  opacity: 1;
  border: 0.02rem solid #D9DEF2;
}

.t2 {
  position: absolute;
  left: 0.72rem;
  top: 5.45rem;
  width: 78.8%;
  height: 0.62rem;
  font-size: 0.25rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #F13A3A;
  line-height: 0.3rem;
}

.t3 {
  position: absolute;
  left: 0.72rem;
  top: 1.81rem;
  width: 78.8%;
  height: auto;
  font-size: 0.25rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;
  line-height: 0.3rem;
}

.next {
  position: absolute;
  left: 1.16rem;
  top: 8.84rem;
  width: 5.2rem;
  height: 0.58rem;
}

.detail_icon_close {
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
  width: 0.5rem;
  height: 0.5rem;
  background: url("../assets/close.svg") no-repeat left center/0.5rem 0.5rem;
}

.van-button--primary {
  color: #fff !important;
  font-weight: 400 !important;
  padding: 0 0.9rem !important;
  border: none !important;
  border-radius: 0.38rem !important;
  font-size: 0.29rem !important;
  background: #5175FB !important;
}
</style>
