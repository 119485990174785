<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
</style>
