import Vue from 'vue'
import axios from 'axios'
import sha1 from 'js-sha1'

// 创建 axios 实例
axios.defaults.withCredentials = true

// 异常拦截处理器
const errorHandler = (error) => {
    if (error.response) {
        const data = error.response.data
        // 从 localstorage 获取 token
        const token = localStorage.getItem("ACCESS_TOKEN")
        if (error.response.status === 403) {
            notification.error({
                message: 'Forbidden',
                description: data.message
            })
        }
        if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
            notification.error({
                message: 'Unauthorized',
                description: 'Authorization verification failed'
            })
            if (token) {
                console.log("ErrorToken:", token);
            }
        }
    }
    return Promise.reject(error)
}

// request interceptor
axios.interceptors.request.use(config => {
    // console.log("Config:", config);
    const token = localStorage.getItem("ACCESS_TOKEN");
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers['Access-Token'] = token;
    }
    // Token签名验证
    var timestamp = Date.parse(new Date()).toString().substr(0, 10);
    var url = config.url.replace(Vue.prototype.global.baseUrl, "");
    var str = 'timestamp=' + timestamp + '&key=mobile&token=' + token + '&url=' + url;
    var signature = sha1(str);
    // console.log("Signature:",signature);
    if (config.method === 'post' && token) {
        if (config.data) {
            config.data['timestamp'] = timestamp
            config.data['signature'] = signature
        } else {
            config.data = {
                timestamp: timestamp,
                signature: signature
            }
        }
    } else if (token) {
        if (config.params) {
            config.params['timestamp'] = timestamp
            config.params['signature'] = signature
        } else {
            config.params = {
                timestamp: timestamp,
                signature: signature
            }
        }
    }
    return config
}, errorHandler)

// response interceptor
axios.interceptors.response.use((response) => {
    // alert(JSON.stringify(response.data));
    return response.data
}, errorHandler)
